@import '../../assets/scss/sparemic_colors.scss';
@import '../../components/navigation/header/header.styles.scss';

.spare-mic {
    iframe {
        display: unset;
    }

    .links {
        padding-bottom: 20px;

        .option{
            padding-right: 20px;
            display: inline-block;
        }

        .option:last-child {
            padding-right: 0px;
        }
    }

    .header {
        .options {
            .option {
                padding-right: 20px;
                a {
                    color: #fff;
                }
            }
        }
        .altoption {
          display: none;
        }
    }

    h1 {
        font-weight: bold;
        font-size: 36pt;
        margin-bottom: 10px;
        color: #fff;

        b {
            color: black;
        }
    }

    h2 {
        font-weight: bold;
        font-size: 24pt;
        color: #fff;
        margin-bottom: 10px;
        margin-top: 10px;

        b {
            color: black;
        }
    }

    h3 {
        color: $description-color;
        font-weight: 600;
        font-size: 16pt;
        margin-top: 10px;

        b {
            color: #fff;
        }
        strong {
            background: linear-gradient(45deg, $logo-color 30%, green 90%);
            background-clip: text;
            color: transparent;
        }
    }

    h4 {
        font-size: 14pt;
    }

    strong {
        background: linear-gradient(45deg, $logo-color 30%, green 90%);
        background-clip: text;
        color: transparent;
    }

    .header {
        border-radius: 0px;
        margin-bottom: 0px;
        background: linear-gradient(0deg, $dark-purple 30%, $medium-purple 90%);

        img {
            width: auto;
        }
    }

    .footer {
        border-top: none;
        color: $light-purple;
        display: grid;
        grid-template-columns: 1fr 1fr;

        a {
            color: $description-color;
        }

        img {
            display: unset;
        }
    }

    .switch-direction {
        direction: rtl;
    }

    .decorated {
        // background-image: url("../../assets/images/blurryBackground.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .banner {
        // position: relative;
        width: 100%;
        // height: 650px;
        background-color: black;

        img {
            display: unset;
        }

        .custom-button {
            margin: auto;
            background: transparent;
            color: $sub-background-color;
            border: 1px solid #FFF;

            &:hover {
                background: white;
                color: $logo-color;
                font-weight: bolder;
            }
        }

        .banner-image-container {
        //   height: 500px;
          text-align: right;
        }

        .banner-image {
            margin: auto;
            width: 409.6px;
            height: 546.4px;
        }

        .banner-logo-container {
            margin-top: 20px;
            margin-bottom: 40px;

            h3 {
                width: 80%;
                margin: 10px;
            }

            button {
                margin-right: 5px;
            }
        }

        .banner-logo {
            width: 200px;
            height: 200px;
        }

        .banner-download {
            width: 200px;
        }

        .banner-video {
            width: 100%;
            height: 550px;
            object-fit: cover;
            opacity: 0.7;
        }

        .banner-text {
            // background-image: url("../../assets/images/Banner.jpg");
            background-color: $medium-purple;
            background-size: cover;
            align-items: center;
            width: 100%;
            height: 100%;
            text-align: left;
            display: grid;
            grid-template-columns: 3fr 3fr;
            margin: auto;

            h1 {
                // padding-right: 20%;
                margin: 0px 0px;
            }

            h3 {
                // padding-right: 20%;
                // width: 500px;
            }

            button {
              margin-left: 0px;
              width: auto;
            }
        }
    }

    .container {
        max-width: 100%;
        padding: 2% 10%;
        text-align: center;
        background-color: $dark-purple;

        h1 {
            font-size: 42pt;
        }

        h5 {
            font-weight: 500;
            color: $description-color;
            margin: 1.67em;
        }

        h4 {
            text-transform: uppercase;
            color: gold;
            font-weight: bold;
            margin: 0px;
            padding: 0px;
        }

        h3 {
            a {
                color: #fff;
            }
        }

        .accordion {
            margin: 60px auto;
            width: 100%;

            li {
                list-style: none;
                width: 100%;
                padding: 20px;
                margin-bottom: 20px;
                border-radius: 10px;
                background: #e3edf7;
                box-shadow: 6px 6px 10px -1px rgba(0,0,0, 0.25);

                label {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    font-size: medium;
                    font-weight: 600;
                    cursor: pointer
                }

                label::before {
                    content: "+";
                    margin-right: 10px;
                    font-size: medium;
                    font-weight: 600;
                }

                input[type="radio"] {
                    display: none;
                }

                input[type="radio"]:checked + label + .content {
                    max-height: 400px;
                }

                input[type="radio"]:checked + label::before {
                    content: "-"
                }

                .content {
                    line-height: 26px;
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height 0.3s, padding 0.3s;
                    text-align: left;

                    p {
                        margin-bottom: 0px;
                    }
                }
            }
        }



        .container-grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin-top: 20px;
            gap: 25px;

            .grid-item {
                text-align: center;
                gap: 20px;

                h2 {
                    text-align: center;
                    font-size: 18pt;
                }

                h3 {
                  font-size: 12pt;
                }

                img {
                    max-width: 300px;
                    height: 300px;
                    object-fit: cover;
                    border-radius: 20px;
                    margin: auto;
                }
            }
        }

        .container-grid-duo {
            display: grid;
            grid-template-columns: 1fr 2fr 1fr;
            margin-top: 20px;

            .left {
                padding: 0px 40px;
                text-align: left;
                margin: auto;
            }

            .center {
              text-align: center;
            }

            ul {
                list-style: none;
                padding: 0px 20px;
                direction: ltr;
                color: $sub-color;
            }

            ul li:before {
                content: '✓';
                color: $logo-color;
            }

            li {
                margin-top: 5px;
            }

            // img {
            //     height: 600px;
            // }
        }

        .container-grid-duo-reverse {
            display: grid;
            grid-template-columns: 1fr 3fr;
            margin-top: 20px;

            .selected {
                font-family: Georgia, 'Times New Roman', Times, serif;
                font-variant: small-caps;
                margin: auto;
                h1 {
                    font-size: xx-large;
                }

                h1, h3 {
                    color: $description-color;

                    a, a:visited {
                        color: $description-color;
                    }
                }
            }
        }
    }

    .container-grid-cinco {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        margin-top: 20px;
        gap: 25px;

        .grid-item {
            text-align: center;
            gap: 20px;

            h2 {
                text-align: center;
                font-size: 18pt;
            }

            h3 {
              font-size: 12pt;
            }

            img {
                max-width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 20px;
                margin: auto;
            }
        }
    }

    .container-gray {
        background-color: $dark-purple;
        border-top: 1px solid $light-purple;
        max-width: 100%;
    }

    .container-black {
        background-color: black;
        border-top: 1px solid gray;
        max-width: 100%;

        img {
          display: initial;
        }

        a {
          display: flex;
          justify-content: center;
          color: white;
          font-size: 14pt;
          font-weight: bold;
          padding: 20px;

          img {
            width: 800px;
          }
        }
    }

    #mc_embed_signup {
        background: none;

        form {
            padding: 0px;

            .indicates-required {
                color: $sub-background-color;
            }

            label {
                color: $sub-background-color;
            }

            input {
                border: none;
                border-bottom: 1px solid $sub-background-color;
                background-color: transparent;
                color: $sub-background-color;
            }

            input[type=submit] {
                text-transform: uppercase;
                font-family: 'Open Sans Condensed';
                font-weight: bolder;
                min-width: 165px;
                height: 50px;
                letter-spacing: 0.5px;
                width: 20%;
                border-radius: 20px;
                outline: none;
                display: inline;
                // background-color: $pink-color;
                background: transparent;
                border: 1px solid #FFF;
                color: $sub-background-color;

                &:hover {
                background: white;
                  color: $logo-color;
                  font-weight: bold;
                }
            }
        }
    }

    #expressYourself {
        height: 500px;
    }

    #fiLogo {
        height: 200px;
    }
}

@media only screen and (max-width: 1024px) {
    .spare-mic {
        .links {
            padding-bottom: 20px;

            .option {
                padding-right: 20px;
            }

            .option:last-child {
                padding-right: 0px;
            }
        }

        .header {
            .options {
                .option {
                    padding-right: 20px;
                    a {
                        color: #fff;
                    }
                }
            }
            .altoption {
              display: none;
            }
        }

        h1 {
            font-weight: bold;
            font-size: 36pt;
            margin-bottom: 10px;
            color: #fff;

            b {
                color: black;
            }
        }

        h2 {
            font-weight: bold;
            font-size: 24pt;
            color: #fff;
            margin-bottom: 10px;
            margin-top: 10px;

            b {
                color: black;
            }
        }

        h3 {
            color: $description-color;
            font-weight: 600;
            font-size: 16pt;
            margin-top: 10px;

            b {
                color: #fff;
            }
            strong {
                background: linear-gradient(45deg, $logo-color 30%, green 90%);
                background-clip: text;
                color: transparent;
            }
        }

        h4 {
            font-size: 14pt;
        }

        strong {
            background: linear-gradient(45deg, $logo-color 30%, green 90%);
            background-clip: text;
            color: transparent;
        }

        .header {
            border-radius: 0px;
            margin-bottom: 0px;
            background: linear-gradient(0deg, $dark-purple 30%, $medium-purple 90%);

            img {
                width: auto;
            }
        }

        .footer {
            border-top: none;
            color: $light-purple;
            display: inline;

            a {
                color: $description-color;
            }
        }

        .switch-direction {
            direction: rtl;
        }

        .decorated {
            // background-image: url("../../assets/images/blurryBackground.jpeg");
            background-repeat: no-repeat;
            background-size: cover;
        }

        .banner {
            // position: relative;
            width: 100%;
            // height: 650px;
            background-color: black;

            .product-hunt {
                max-width: 90%;
                height: auto;
            }

            .custom-button {
                margin: auto;
                background: transparent;
                color: $sub-background-color;
                border: 1px solid #FFF;

                &:hover {
                    background: white;
                    color: $logo-color;
                    font-weight: bolder;
                }
            }

            .banner-image-container {
            //   height: 500px;
              text-align: right;
            }
            .banner-image {
                margin: auto;
                width: 100%; //409.6px;
                height: auto; //546.4px;
            }

            .banner-logo {
                width: 200px;
                height: 200px;
            }

            .banner-download {
                width: 200px;
            }

            .banner-video {
                width: 100%;
                height: 550px;
                object-fit: cover;
                opacity: 0.7;
            }

            .banner-text {
                // background-image: url("../../assets/images/Banner.jpg");
                background-color: $medium-purple;
                background-size: cover;
                align-items: center;
                width: 100%;
                height: 100%;
                text-align: left;
                display: grid;
                grid-template-columns: 3fr 3fr;
                grid-template-areas: "banner-image-container banner-image-container" "banner-logo-container banner-logo-container";
                margin: auto;

                h1 {
                    // padding-right: 20%;
                    margin: 0px 0px;
                }

                h3 {
                    // padding-right: 20%;
                    // width: 500px;
                }

                button {
                  margin-left: 0px;
                  width: auto;
                }
            }

            .banner-image-container {
                grid-area: banner-image-container;
                text-align: center;
            }

            .banner-logo-container {
                margin-top: 20px;
                margin-bottom: 40px;
                grid-area: banner-logo-container;
                text-align: center;

                button {
                  padding-bottom: 20px;
                }

                h3 {
                    margin: 20px auto;
                    max-width: 65%;
                }
            }

        }

        .container {
            .videoFrame {
                width: 560px;
                height: 315px;
            }

            padding: 20px 10%;
            text-align: center;
            // background-color: $dark-purple;
            h1 {
                font-size: 42pt;
            }

            h5 {
                font-weight: 500;
                color: $description-color;
            }

            h4 {
                text-transform: uppercase;
                color: gold;
                font-weight: bold;
                margin: 0px;
                padding: 0px;
            }

            .container-grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-areas: "content1 content1 content1" "content2 content2 content2" "content3 content3 content3";
                margin-top: 20px;
                gap: 25px;

                .grid-item {
                    text-align: center;
                    gap: 20px;

                    h2 {
                        text-align: center;
                    }

                    img {
                        max-width: 100%; //300px;
                        height: auto; // 300px;
                        object-fit: cover;
                        border-radius: 20px;
                        margin: auto;
                    }
                }

                .content1 {
                    grid-area: content1;
                }

                .content2 {
                    grid-area: content2;
                }

                .content3 {
                    grid-area: content3;
                }
            }

            .container-grid-duo {
                display: grid;
                grid-template-columns: 1fr 2fr 1fr;
                margin-top: 20px;

                .left {
                    padding: 0px 40px;
                    text-align: left;
                    margin: auto;
                }

                .center {
                  text-align: center;
                }

                ul {
                    list-style: none;
                    padding: 0px 20px;
                    direction: ltr;
                    color: $sub-color;
                }

                ul li:before {
                    content: '✓';
                    color: $logo-color;
                }

                li {
                    margin-top: 5px;
                }

                // img {
                //     height: 600px;
                // }
            }


            .container-grid-duo-reverse {
                display: grid;
                grid-template-columns: 1fr 3fr;
                margin-top: 20px;

                .selected {
                    font-family: Georgia, 'Times New Roman', Times, serif;
                    font-variant: small-caps;
                    margin: auto;
                    h1 {
                        font-size: xx-large;
                    }

                    h1, h3 {
                        color: $description-color;

                        a, a:visited {
                            color: $description-color;
                        }
                    }
                }
            }
        }
        .container-grid-cinco {
          display: grid;
          grid-template-columns: 1fr 1fr; /* Two equal columns */
          grid-template-areas:
              "content1 content2"
              "content3 content4"
              "content5 content5"; /* Spanning full width for the last item */
          margin-top: 20px;
          gap: 25px;

            .grid-item {
                text-align: center;
                gap: 20px;

                h2 {
                    text-align: center;
                }

                h3 {
                  font-size: 12pt;
                }

                img {
                    max-width: 100%; //300px;
                    height: 100px; // 300px;
                    object-fit: cover;
                    border-radius: 20px;
                    margin: auto;
                }
            }

            .content1 {
                grid-area: content1;
            }

            .content2 {
                grid-area: content2;
            }

            .content3 {
                grid-area: content3;
            }
            .content4 {
                grid-area: content4;
            }
            .content5 {
                grid-area: content5;
            }
        }

        .container-gray {
            background-color: $dark-purple;
            border-top: 1px solid $light-purple;
        }

        .container-black {
            a {
              img {
                width: 100%;
              }
            }
        }

        #mc_embed_signup {
            background: none;

            form {
                padding: 0px;

                .indicates-required {
                    color: $sub-background-color;
                }

                label {
                    color: $sub-background-color;
                }

                input {
                    border: none;
                    border-bottom: 1px solid $sub-background-color;
                    background-color: transparent;
                    color: $sub-background-color;
                }

                input[type=submit] {
                    text-transform: uppercase;
                    font-family: 'Open Sans Condensed';
                    font-weight: bolder;
                    min-width: 165px;
                    height: 50px;
                    letter-spacing: 0.5px;
                    width: 20%;
                    border-radius: 20px;
                    outline: none;
                    display: inline;
                    // background-color: $pink-color;
                    background: transparent;
                    border: 1px solid #FFF;
                    color: $sub-background-color;

                    &:hover {
                    background: white;
                      color: $logo-color;
                      font-weight: bold;
                    }
                }
            }
        }

        #expressYourself {
            height: 500px;
        }

        #fiLogo {
            height: 200px;
        }
    }
}

// mobile

@media only screen and (max-width: 800px) {
    .spare-mic {
        h1 {
            font-weight: bold;
            font-size: 24pt;
        }

        .header {
            .options {
                display: none;
            }

            .option {
                padding-right: 20px;
                a {
                    color: #fff;
                }
            }

            .altoption {
              display: flex;
              a {
                  color: #fff;
              }
            }
        }

        .banner {
            .banner-text {
            }

            .banner-image-container {
              display: grid;
            }
            .banner-image {
                align-self: center;
                margin: auto;
                width: 300px;
            }
        }

        .container {
            .videoFrame {
                width: 100%;
            }

            .container-grid {
                grid-template-columns: 1fr;
                grid-template-areas:
                    "content1" "content2"
                    "content3 content4"; /* Spanning full width for the last item */

                .grid-item {
                    text-align: center;
                    h3 {
                        padding: 0%;
                        margin-bottom: 20px;
                    }
                    img {
                        max-width: 250px;
                        height: 250px;
                    }
                }
            }

            .container-grid-duo {
                display: grid;
                grid-template-columns: 1fr;
                margin-top: 20px;

                h2 {
                    padding: 1% 3%;
                    text-align: center;
                }

                h3 {
                    padding: 1% 3%;
                    margin-bottom: 20px;
                    text-align: center;
                }

                h4 {
                    text-align: center;
                }

                img {
                    height: 500px;
                    margin: auto;
                }

                ul {
                    text-align: center;
                }
            }

            .container-grid-duo-reverse {
                display: grid;
                grid-template-columns: 1fr;
                margin-top: 20px;

                img {
                    margin: auto;
                }

                .selected {
                    font-family: Georgia, 'Times New Roman', Times, serif;
                    font-variant: small-caps;

                    h1 {
                        font-size: xx-large;
                    }

                    h1, h3 {
                        color: $description-color;
                    }
                }
            }
        }

        #expressYourself {
            height: 220px;
        }

        #enterprise {
            width: 300px;
            height: 300px;
        }

    }
}
