$main-background-color: #f4f5f7;
$sub-background-color: #ffffff;
$pink-color: #fecacf;
$blue-color: #c0e5f4;
$logo-color: #19275A;
$border-color: #e9e9e9;
$text-color: #000000;
$sub-color: #777777;
$description-color: #B3BAC3;
$light-purple: #9079D9;
$medium-purple: #5A37D2; 
$dark-purple: #44299E;